import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Basic backbone mixin for a remote search component.
 * Components should extend the "search" method to provide Api promise for fetching the items.
 * @module remoteSelectMixin
 */
export const remoteSelectMixin = {
  /**
   * Debounce the search so we don't search too ofter on each keystroke
   */
  mixins: [debounceMixin(['__search'], 250)],

  props: {
    /**
     * Predefined list of items
     * @type {Array}
     */
    items: {
      type: Array,
      default: () => ([])
    },

    /**
     * Selected items
     * @type {Array}
     */
    value: {
      type: [Array, String, Number, Object],
      default: null
    }
  },

  data () {
    return {
      /**
       * Found items from the search
       */
      foundItems: [],
      isLoading: false,
      cancelToken: null
    }
  },

  computed: {
    /**
     * The getter/setter pair for easier v-model binding
     */
    computedValue: generateComputedSetterWithEmit(),

    /**
     * Determines the items to loop over.
     *
     * If it has items provided through the prop, return them.
     *
     * @returns {Array}
     */
    itemsToLoopOver () {
      return this.shouldSearch ? this.foundItems : this.items
    },

    /**
     * If no items are provided via prop, a search is required
     * @returns {boolean}
     */
    shouldSearch () {
      return !this.$options.propsData.items
    },

    /**
     * Generic error message on search failure. Can be overridden
     * @returns {string}
     */
    errorMessage () {
      return this.$t('errors.cannot_find_items')
    }
  },

  /**
   * Whether we should search for items or not
   */
  mounted () {
    if (this.shouldSearch) {
      this.__search()
    }
  },

  methods: {
    /**
     * This is the default search method that should be used for hydrating the element on mounted
     * And the remote prop inside the el-select tag
     * @param query
     */
    __search (query) {
      if (this.cancelToken) {
        this.cancelToken.cancel()
      }
      this.isLoading = true
      this.cancelToken = this.$api.cancelToken()

      const payload = {
        params: {
          search: query
        },
        cancelToken: this.cancelToken.token
      }

      this.search(payload)
        .then((response) => {
          this.foundItems = response
        })
        .catch(error => {
          if (this.$api.isCancelToken(error)) return
          this.$displayRequestError(error, this.errorMessage, 500, [404])
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    /**
     * Method to implement on the extending component
     * @private
     * @return {Promise<never>}
     */
    search () {
      return Promise.reject(new Error('Should be implemented by the component'))
    }
  }
}
