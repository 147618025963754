import { subYears, subDays, isAfter, isBefore, differenceInDays } from 'date-fns'

import { withParams } from 'vuelidate/lib/'
import { req, len } from 'vuelidate/lib/validators/common'
import { countWords, stripSpecialChars } from './index'

export const matchRegex = (type, pattern) => {
  return withParams({ type }, value => pattern.test(value))
}

export const maxWordCount = (length, separator) => withParams({ type: 'max', max: length }, value => {
  let test = value => !req(value) || (countWords(value) <= length)
  if (separator) return value.split(separator).every(test)
  return test(value)
})

export const youtubeUrl = matchRegex('youtubeUrl', /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/)
export const facebookUrl = matchRegex('facebookUrl', /^https:\/\/(?:www\.)?facebook\.com\/(?:.*?)\/(posts|videos|photos)\/.+\/?/)
export const instagramUrl = matchRegex('instagramUrl', /^https?:\/\/(?:www\.)?instagram\.com\/p\/\S{11,}/)
export const twitterUrl = matchRegex('twitterUrl', /^https?:\/\/(?:www\.)?twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)\/?(?:\?\w*)?$/)
export const linkedinUrl = matchRegex('linkedinUrl', /^(https?:\/\/)(www\.)?(linkedin.com)\/feed\/update\/?/)
export const ignoreSocialUrl = matchRegex('ignoreSocialUrl', /^(?!.*(youtube|facebook|instagram|twitter)\.).*$/)

/**
 * Special Validation rule used when manually creating a clipping.
 * Validates Web, Youtube, Twitter, Facebook and Instagram urls
 * @param {string} url - the value that is validated, currently URL
 * @param {object} form - the whole form object, passed by Vuelidate
 * @return {boolean}
 */
export const clippingUrl = function (url, form) {
  switch (form.type) {
    case 'web':
      return ignoreSocialUrl(url)
    case 'youtube':
      return youtubeUrl(url)
    case 'facebook':
      return facebookUrl(url)
    case 'instagram':
      return form.useUrl ? instagramUrl(url) : true
    case 'twitter':
      return twitterUrl(url)
    case 'linkedin':
      return linkedinUrl(url)
    default:
      return true
  }
}

/**
 * Validates a comma separated list of values by the provided Vuelidate validator
 * That returns a new function that validates each value of the comma separated string
 * @param validator - Vuelidate Validator
 * @param {String | RegExp} [separator=,] - the separator by which to split the value
 * @return {function(*): boolean} - Returns a function that takes the validator as a param
 */
export const commaSeparatedListValidator = (validator, separator = ',') => (value) => {
  return !value.split(separator).some(v => !validator(v.trim()))
}

/**
 * Bellow functions are used on Social
 */

function is2DaysAgo (time) {
  let twoDaysAgo = subDays(new Date(), 3)
  return isAfter(time, twoDaysAgo)
}

function is2YearsAgo (time) {
  let twoYearsAgo = subYears(new Date(), 2)
  return isBefore(time, twoYearsAgo)
}

function has90DaysDifference (start, end) {
  let differenceInDays2 = Math.abs(differenceInDays(start, end))
  return differenceInDays2 > 90
}

export function isStartDateValidForSocial (time, end) {
  return is2DaysAgo(time) || is2YearsAgo(time) || has90DaysDifference(end, time) || isAfter(time, end)
}

export function isEndDateValidForSocial (time, start) {
  return is2DaysAgo(time) || is2YearsAgo(time) || has90DaysDifference(start, time) || isBefore(time, start)
}

export function isSimpleUrl (value) {
  // Originally taken from Vuelidate https://github.com/monterail/vuelidate/blob/master/src/validators/url.js#L2
  const urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i

  return !req(value) || urlRegex.test(value)
}

export const isPasswordStrongEnough = (length = 6) => withParams({ min: length }, value => {
  return !req(value) || new RegExp(`^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{${length},}$`).test(value)
})

/**
 * Wraps a Vuelidate validator so it can receive a sanitized value
 * @example rules: { required, minLength: sanitizeStringForValidator(minLength(3)) }
 * @param validator - Vuelidate validator
 * @return {function(*=): *}
 */
export const sanitizeStringForValidator = (validator) => (value) => validator(stripSpecialChars(value))

/**
 * Checks if the field has length before and after stripping special chars
 * @param {string} value
 * @return {boolean}
 */
export const requiredExcludingSpecialChars = (value) => {
  // Story is required (must have some content)
  return !(len(value) !== 0 && len(stripSpecialChars(value)) === 0)
}
