<template>
  <el-select
    v-model="computedValue"
    v-loading="isLoading"
    :remote="shouldSearch"
    :remote-method="__search"
    :placeholder="$t('components.organisation_picker.find_organisation')"
    v-bind="$attrs"
    value-key="id"
    filterable
    default-first-option
    class="OrganisationsPicker"
  >
    <el-option
      v-for="organisation in itemsToLoopOver"
      :key="organisation.id"
      :label="organisation.name"
      :value="organisation"
    />
  </el-select>
</template>

<script>
import _orderBy from 'lodash/orderBy'
import { remoteSelectMixin } from '@hypefactors/shared/js/mixins/remoteSelectMixin'

/**
 * Creates a searchable organisation select box
 * @module OrganisationsPicker
 */
export default {
  name: 'OrganisationsPicker',

  extends: remoteSelectMixin,

  computed: {
    errorMessage () {
      return this.$t('errors.cannot_fetch_organisations')
    }
  },

  methods: {
    /**
     * Overridden method to search and order the items by name
     * @param payload
     * @returns {Promise}
     */
    search (payload) {
      payload.params.limit = 20
      payload.params.sort = 'name-length-smaller'
      payload.params.include = ['brands']

      return this.$api.get('/organisations', payload)
        .then(response => response.data.data)
        .then(data => {
          return _orderBy(data, 'name')
        })
    }
  }
}
</script>
